import * as moment from 'moment';

import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { AccessLog, AecAttendanceReport } from 'src/definitions/report';


import { UserState } from 'src/user-state';

import { SnackBar } from 'src/snack-bar';

import { ApiService } from 'src/services/api';
import { CognitoService } from 'src/services/cognito.service';
import { Utility } from 'src/services/utility.service';

import { ReportDateDialog } from './report-date-dialog';

declare let pdfMake: any;


@Component({
  selector: 'prozper-report-download',
  templateUrl: './report-download.component.html',
  styleUrls: [ './report-download.component.scss' ],
  providers: [ AccessLog, AecAttendanceReport, ReportDateDialog ]
})
export class ReportDownloadComponent {
  public busy = false;
  public reportDateForm = new UntypedFormGroup({
    reportDate: new UntypedFormControl(new Date())
  });


  // Constructor ___________________________________________________________________________________

  constructor(private api: ApiService,
    private cognitoService: CognitoService,
    private user: UserState,
    private accessLog: AccessLog,
    private aecAttendanceReport: AecAttendanceReport,
    private reportDateDialog: ReportDateDialog,
    private snackBar: SnackBar) {
    pdfMake.fonts = {
      csChatThai: {
        normal: 'CSChatThai.ttf'
      },
      csPraJad: {
        normal: 'CSPraJad.ttf',
        bold: 'CSPraJad-bold.ttf',
        italics: 'CSPraJad-italic.ttf',
        bolditalics: 'CSPraJad-bolditalic.ttf'
      },
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Bold.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-BoldItalic.ttf'
      },
      sarabun: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNew-Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf'
      }
    };      
  }


  // Accessors _____________________________________________________________________________________

  //................................................................................................
  public get reportDate(): UntypedFormControl {
    return this.reportDateForm.get('reportDate') as UntypedFormControl;
  }

  //................................................................................................
  public get showAdminAction(): boolean {
    return this.user.admin;
  }
 
  
  // Public Methods ________________________________________________________________________________

  //................................................................................................
  public validateReportDate() {
    let backDateLimit = moment().subtract(1, 'month').toDate();
    let today = new Date();
    if (moment(this.reportDate.value).isAfter(today, 'date')) {
      this.reportDate.setValue(new Date());
    } else if (moment(this.reportDate.value).isBefore(backDateLimit, 'date')) {
      this.reportDate.setValue(backDateLimit);
    }
  }

  //................................................................................................
  public async printAecAttendanceReport() {
    if (moment.isDate(this.reportDate.value)) {
      try {
        this.busy = true;
        let report = await this.aecAttendanceReport.create(this.reportDate.value);
        try {
          let reportDate = moment(this.reportDate.value).format('YYYY-MM-DD');
          await this.api.logger.put(new Date(), reportDate, this.user.name, this.user.surname);
        } catch(error) {
          console.log(error);
        }
        this.busy = false;
        pdfMake.createPdf(report).open();  
      } catch(error) {
        this.snackBar.alert('ไม่สามารถสร้างรายงานได้');
      }
    } else {
      this.snackBar.warn('วันที่ไม่ถูกต้อง');
    }
  }

  //................................................................................................
  public async exportAccessLog() {
    let reportDate = await this.reportDateDialog.present();
    if (reportDate) {
      try {
        this.busy = true;
        let result = await this.accessLog.create(reportDate);
        if (result === null) {
          this.snackBar.notify(`ไม่มีการดูรายงานเวลาเข้า-ออกในวันที่ ${Utility.dateFormat(reportDate, 'SSF')}`);
        }
        this.busy = false;
      } catch(error) {
        this.busy = false;
        this.snackBar.alert('ไม่สามารถสร้างบันทึกการดูรายงานเวลาเข้า-ออก');
      }  
    }
  }
}