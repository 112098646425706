export interface Beacon {
  uuid: string;
  name?: string;
  major: number;
  minor: number;
};

export enum RequestStatus {
  Submitted,            // 0
  Cancelled,            // 1
  Approved,             // 2
  Rejected,             // 3
  Suspended,            // 4
  Closed,               // 5
  Returned,             // 6
  SubmittedForReview    // 7
};

export interface Holiday {
  date: Date;
  nameTh: string;
  nameEn: string;
};

export interface ShiftName {
  nameTh: string;
  nameEn: string;
};


export enum AttendanceStatus {
  OnTime,
  Late,
  Absent,
  Early,
  NoRecord,
  EarlyBreak,
  LateBreak,
  OnLeave,
  DayOff,
  NotAvailable
}

export enum ClockingMode {
  None,
  iBeacon,
  GPS,
  OffsiteGPS
}

export enum MemoStatus {
  New,
  OnGoing,
  Closed
};

export interface ClockingData {
  time: Date;
  confirm: boolean;
  timezone?: string;
  locationId?: string;
  locationCode?: string;      // For AEC timesheet integration purpose
  locationInfo?: string;
  beacon?: Beacon;
  latitude?: number;
  longitude?: number;
  geocode?: any;
  reason?: string;
  otherReason?: string;
  retry?: number;
  submittedTime?: string;
  updatedTime?: string;

  // Extended location information
  clockingPosition?: any;
  mapPosition?: any;
  distance?: number;

  // For display
  clockingMode?: ClockingMode;
  clockedByGPS?: boolean;
  status?: AttendanceStatus;
  formattedTime?: string;
  formattedPosition?: string;
  formattedAddress?: string;
  formattedDistance?: string;
  distanceLabel?: string;
  statusColor?: string;
  offsiteClockingReason?: string;
}

// export interface ClockingStage extends ClockingData {
//   // Extended location information
//   clockingPosition?: google.maps.LatLng;
//   mapPosition?: google.maps.LatLng;
//   distance?: number;

//   // For display
//   clockingMode?: ClockingMode;
//   clockedByGPS?: boolean;
//   status?: AttendanceStatus;
//   formattedTime?: string;
//   formattedPosition?: string;
//   formattedAddress?: string;
//   formattedDistance?: string;
//   distanceLabel?: string;
//   statusColor?: string;
//   offsiteClockingReason?: string;
// };

export interface Comment {
  id: string;
  nameTh: string;
  nameEn: string;
  surnameTh: string;
  surnameEn: string;
  date: Date;
  detail: string;
};

export interface Memo {
  id?: string;
  recDate?: string;
  memoKey?: string;
  type: number;
  detail: string;
  dueDate: Date; 
  status: MemoStatus;
  comments?: Array<Comment>;
};

export interface DetailedMemo extends Memo {
  startTime: Date;
  endTime: Date;
  reference1: string;
  reference2: string;
  reference3: string;
  description: string;
};

export interface Checkpoint {
  id: number;
  reference1: string;
  reference2: string;
  reference3: string;
  description: string;
  memos: Array<Memo>;
  start: ClockingData;
  end: ClockingData;
};

export interface TimeTable {
  shiftIndex: number;
  shiftName: ShiftName,
  workingDay: boolean;
  startTime: Date;
  endTime: Date;
  breakStartTime: Date;
  breakEndTime: Date;
  otStartTime?: Date;
  flexibleHours?: boolean;
  multipleClocking?: boolean;
  breakTimeClocking?: boolean;
  lateDeadline?: Date;
  absentDeadline?: Date;
  absentDurations?: Array<{
    duration: number;
    absentTime: number;
  }>;
};

export interface TimeAttendance {
  id?: string;
  date: Date;
  recDate?: string;
  clockIn: ClockingData;
  clockOut: ClockingData;
  breakStart?: ClockingData;
  breakEnd?: ClockingData;
  checkpoints?: Array<Checkpoint>;
  holiday?: Holiday;
  timetable?: TimeTable;
  otApproved?: boolean;
  otStatus?: RequestStatus;
  amendStatus?: RequestStatus;

  // id?: string;
  // date: Date;
  // clockIn: ClockingData;
  // clockOut: ClockingData;
  // otApproved?: boolean;
  // otStatus?: RequestStatus;
  // amendStatus?: RequestStatus;
}

export const MemoStatusList = [
  { value: MemoStatus.New, nameTh: 'รายการใหม่', nameEn: 'New' },
  { value: MemoStatus.OnGoing, nameTh: 'อยู่ระหว่างดำเนินการ', nameEn: 'On Going'},
  { value: MemoStatus.Closed, nameTh: 'แล้วเสร็จ', nameEn: 'Closed' }
];