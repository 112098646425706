import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { UserState } from 'src/user-state';

import { ReportDownloadComponent } from './report-download.component';
import { ReportDateDialogComponent } from './report-date-dialog';

import { InternationalDateAdapter } from './intl-date-adapter';


@NgModule({
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule,
    // RouterModule.forChild([
    //   { path: '', component: ReportDownloadComponent }
    // ]),

    MatButtonModule, MatDatepickerModule, MatDialogModule, MatFormFieldModule,
    MatIconModule, MatInputModule, MatNativeDateModule, MatProgressBarModule,
    MatTooltipModule
  ],
  declarations: [
    ReportDownloadComponent,
    ReportDateDialogComponent
  ],
  providers: [
    UserState,

    { provide: DateAdapter, useClass: InternationalDateAdapter },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }
  ]
})
export class ReportDownloadModule { }