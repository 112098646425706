<div class="pz-icon-dialog-header">
  <div class="pz-header-icon">
    <img src="./assets/icons/header-icons/forgot-password.svg">
  </div>

  <div class="pz-icon-header-toolbar">
    <button mat-icon-button color="accent"
      class="pz-icon-header-toolbar-button"
      [autofocus]="false"
      (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="pz-icon-header-title">
    <div class="pz-icon-header-title-text">ลืมรหัสผ่าน</div>
  </div>
</div>

<div class="pzl-input-panel">
  <form [formGroup]="forgotPasswordForm">
    <mat-form-field>
      <mat-label>ชื่อผู้ใช้</mat-label>
      <input matInput formControlName="username">
      <mat-error *ngIf="username && username.hasError('required')">
        กรุณาใส่รหัสผู้ใช้
      </mat-error>
    </mat-form-field>

    <button mat-fab color="accent"
      [disabled]="submitDisabled"
      (click)="forgotPassword()">
      <mat-icon>done</mat-icon>
    </button>
  </form> 
</div>