import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { VerifyUserDialogComponent } from './verify-user-dialog.component';


@Injectable()
export class VerifyUserDialog {


  // Constructor ___________________________________________________________________________________

  constructor(private dialog: MatDialog) { }


  // Public Methods ________________________________________________________________________________

  //................................................................................................
  public present() {
    this.dialog.open(VerifyUserDialogComponent, { width: '320px', panelClass: 'pz-dialog-container' });
  }
}