<div class="pz-background pzl-image"></div>

<div class="pz-panel">
  <mat-progress-bar *ngIf="busy" mode="indeterminate"></mat-progress-bar>

  <div class="pz-toolbar">
    <div class="pz-header-container">
      <div class="pz-header-text">รายงานเวลาเข้า-ออก</div>
    </div>
    <div class="pz-toolbar-button-container">
      <ng-container *ngIf="showAdminAction">
        <button mat-mini-fab color="accent" matTooltip="สร้างไฟล์บันทึกการดึงรายงานเวลาเข้า-ออก"
          (click)="exportAccessLog()">
          <mat-icon svgIcon="zi_file_download"></mat-icon>
        </button>  
      </ng-container>
    </div>
  </div> 

  <div class="pzl-content">
    <form [formGroup]="reportDateForm">
      <mat-form-field>
        <mat-label>วันที่</mat-label>
        <input matInput [matDatepicker]="reportDatepicker"
          formControlName="reportDate"
          (dateChange)="validateReportDate()">
        <mat-datepicker-toggle matSuffix [for]="reportDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #reportDatepicker></mat-datepicker>
      </mat-form-field>  
    </form>

    <button mat-mini-fab color="accent" (click)="printAecAttendanceReport()">
      <mat-icon svgIcon="ionicon_print"></mat-icon>
    </button>
  </div>
</div>