<div class="pz-icon-dialog-header">
  <div class="pz-header-icon">
    <img src="./assets/icons/header-icons/verify-user.svg">
  </div>

  <div class="pz-icon-header-toolbar">
    <button mat-icon-button color="accent"
      class="pz-icon-header-toolbar-button"
      [autofocus]="false"
      (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="pz-icon-header-title">
    <div class="pz-icon-header-title-text">ป้อนรหัสยืนยัน</div>
  </div>
</div>

<div class="pzl-input-panel">
  <form [formGroup]="verifyUserForm">
    <mat-form-field>
      <mat-label>รหัสยืนยัน (ตัวเลข 6 หลัก)</mat-label>
      <input matInput mask="000000" formControlName="verificationCode">
      <mat-error *ngIf="verificationCode && verificationCode.hasError('required')">
        กรุณาใส่รหัสยืนยัน
      </mat-error>
      <mat-error *ngIf="verificationCode && verificationCode.hasError('Mask error') && !verificationCode.hasError('required')">
        รหัสยืนยันไม่ถูกต้อง
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>ชื่อผู้ใช้</mat-label>
      <input matInput formControlName="username">
      <mat-error *ngIf="username && username.hasError('required')">
        กรุณาใส่รหัสผู้ใช้
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>รหัสผ่านใหม่</mat-label>
      <input type="password" matInput formControlName="newPassword">
      <mat-error *ngIf="newPassword && newPassword.hasError('required')">
        กรุณาใส่รหัสผ่านใหม่
      </mat-error>
      <mat-error *ngIf="newPassword && newPassword.hasError('pattern') && !newPassword.hasError('required')">
        รหัสผ่านต้องมีทั้งตัวอักษรและตัวเลขอย่างน้อย 8 ตัวอักษร
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>ยืนยันรหัสผ่านใหม่</mat-label>
      <input type="password" matInput formControlName="confirmPassword" [errorStateMatcher]="confirmValidPasswordMatcher">
      <mat-error *ngIf="confirmPassword && confirmPassword.hasError('required')">
        กรุณาใส่ยืนยันรหัสผ่านใหม่
      </mat-error>
      <mat-error *ngIf="confirmPassword && confirmPassword.hasError('pattern') && !confirmPassword.hasError('required')">
        รหัสผ่านต้องมีทั้งตัวอักษรและตัวเลขอย่างน้อย 8 ตัวอักษร
      </mat-error>
      <mat-error *ngIf="verifyUserForm.errors?.['confirmNotMatched']">
        รหัสผ่านที่ยืนยันไม่ตรงกับรหัสผ่าน
      </mat-error>
    </mat-form-field>

    <button mat-fab color="accent"
      [disabled]="submitDisabled"
      (click)="verifyUser()">
      <mat-icon>done</mat-icon>
    </button>
  </form>
</div>