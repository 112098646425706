import * as moment from 'moment';

import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'prozper-report-date-dialog',
  templateUrl: './report-date-dialog.component.html',
  styleUrls: ['./report-date-dialog.component.scss']
})
export class ReportDateDialogComponent {
  public reportDateForm = new UntypedFormGroup({
    reportDate: new UntypedFormControl(new Date())
  });


  // Constructor ___________________________________________________________________________________

  constructor(private dialogRef: MatDialogRef<ReportDateDialogComponent>) { }


  // Accessors _____________________________________________________________________________________

  //................................................................................................
  public get reportDate(): UntypedFormControl {
    return this.reportDateForm.get('reportDate') as UntypedFormControl;
  }


  // View Controllers ______________________________________________________________________________

  //................................................................................................
  public close() {
    this.dialogRef.close();
  }

  //................................................................................................
  public submit() {
    this.dialogRef.close(this.reportDate.value);
  }

  //................................................................................................
  public validateReportDate() {
    let today = new Date();
    if (moment(this.reportDate.value).isAfter(today, 'date')) {
      this.reportDate.setValue(new Date());
    }
  }
}