<div class="pz-icon-dialog-header">
  <div class="pz-header-icon">
    <img src="./assets/icons/header-icons/excel.svg">
  </div>

  <div class="pz-icon-header-toolbar">
    <button mat-icon-button color="accent"
      class="pz-icon-header-toolbar-button"
      [autofocus]="false"
      (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="pz-icon-header-title">
    <div class="pz-icon-header-title-text">สร้างไฟล์</div>
  </div>
</div>

<div class="pzl-input-panel">
  <form [formGroup]="reportDateForm">
    <mat-form-field>
      <mat-label>วันที่</mat-label>
      <input matInput [matDatepicker]="reportDatepicker"
        formControlName="reportDate"
        (dateChange)="validateReportDate()">
      <mat-datepicker-toggle matSuffix [for]="reportDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #reportDatepicker></mat-datepicker>
    </mat-form-field>

    <button mat-fab color="accent"
      (click)="submit()">
      <mat-icon>done</mat-icon>
    </button>
  </form> 
</div>