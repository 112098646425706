import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';

import { PasswordRegEx } from 'src/definitions/validation';
import { NewPasswordValidator, ConfirmValidPasswordMatcher } from 'src/definitions/validation';

import { SnackBar } from 'src/snack-bar';

import { UnauthenticatedAPI } from '../unauthenticated.api';


@Component({
  selector: 'prozper-verify-user-dialog',
  templateUrl: './verify-user-dialog.component.html',
  styleUrls: ['./verify-user-dialog.component.scss']
})
export class VerifyUserDialogComponent {
  public verifyUserForm = new UntypedFormGroup({
    verificationCode: new UntypedFormControl(null, [ Validators.required ]),
    username: new UntypedFormControl(null, [ Validators.required ]),
    newPassword: new UntypedFormControl(null, [ Validators.required, Validators.pattern(PasswordRegEx) ]),
    confirmPassword: new UntypedFormControl(null, [ Validators.required, Validators.pattern(PasswordRegEx) ])
  }, { validators: NewPasswordValidator });
  public confirmValidPasswordMatcher = new ConfirmValidPasswordMatcher();


  // Constructor ___________________________________________________________________________________

  constructor(private dialogRef: MatDialogRef<VerifyUserDialogComponent>,
    private snackBar: SnackBar) { }


  // Accessors _____________________________________________________________________________________

  //................................................................................................
  public get verificationCode() {
    return this.verifyUserForm.get('verificationCode');
  };

  //................................................................................................
  public get username() {
    return this.verifyUserForm.get('username');
  };

  //................................................................................................
  public get newPassword() {
    return this.verifyUserForm.get('newPassword');
  };

  //................................................................................................
  public get confirmPassword() {
    return this.verifyUserForm.get('confirmPassword');
  };

  //................................................................................................
  public get submitDisabled(): boolean {
    return this.verifyUserForm.pristine || this.verifyUserForm.dirty && this.verifyUserForm.invalid;
  }


  // View Controllers ______________________________________________________________________________

  //................................................................................................
  public close() {
    this.dialogRef.close();
  }

  //................................................................................................
  public async verifyUser() {
    if (this.verifyUserForm.valid
      && this.verificationCode !== null
      && this.username !== null
      && this.newPassword !== null) {
      try {
        await UnauthenticatedAPI.user.forgotPassword.confirm(this.verificationCode.value, this.username.value, this.newPassword.value);
        this.snackBar.notify('ยืนยันการเปลี่ยนรหัสผ่านเรียบร้อยแล้ว');
        this.dialogRef.close();
      } catch(error) {
        console.log(error);
        this.snackBar.alert(error as Error);
      }
    }
  }
}