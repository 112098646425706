import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { ConfirmationDialogConfig } from './confirmation-dialog.config';

import { ConfirmationDialogComponent } from './confirmation-dialog.component';


@Injectable()
export class ConfirmationDialog {

  
  // Constructor ___________________________________________________________________________________

  constructor(private dialog: MatDialog) { }


  // Public Method _________________________________________________________________________________

  //................................................................................................
  public open(message: string, confirm = 'no'): Promise<boolean> {
    return new Promise(resolve => {
      this.dialog.open(ConfirmationDialogComponent, {
        width: '300px',
        data: {
          message: message,
          confirm: confirm
        } as ConfirmationDialogConfig,
        panelClass: 'pz-dialog-container',
        autoFocus: false  
      })
      .afterClosed()
      .subscribe((confirm) => resolve(confirm));  
    });
  }
}