export class Fault extends Error {
  private _functionName: string | null = null;
  private _cause: Error | null = null;


  // Constructor ___________________________________________________________________________________

  constructor(errorMessage: any, options?: any) {
    super(errorMessage ? errorMessage : 'Error.ApplicationError');
    if (options) {
      if (options.cause) {
        this._cause = options.cause;
      }  
      if (options.functionName) {
        this._functionName = options.functionName;
      }
    }
  }


  // Accessors _____________________________________________________________________________________

  //................................................................................................
  public get functionName(): string | null {
    return this._functionName;
  }

  //................................................................................................
  public get cause(): Error | null {
    return this._cause;
  }


  // Public Methods ________________________________________________________________________________

  //................................................................................................
  public setFunctionName(functionName: string) {
    this._functionName = functionName;
  }
}