import { NativeDateAdapter } from '@angular/material/core';

import * as _ from 'lodash';
import { Injectable } from "@angular/core";

@Injectable()
export class InternationalDateAdapter extends NativeDateAdapter {
  private static isBEYear = false;

  public static setYearToBE() {
    InternationalDateAdapter.isBEYear = true;
  }

  public static setYearToAD() {
    InternationalDateAdapter.isBEYear = false;
  }

  override getYearName(date: Date): string {
    if (InternationalDateAdapter.isBEYear) {
      return (date.getFullYear() + 543).toString();
    } else {
      return super.getYearName(date);
    }
  }

  override getYear(date: Date): number {
    return super.getYear(date)
  }

  override parse(value: any): Date | null {
    let result: Date | null = null;
    if (typeof value === 'number') {
      return new Date(value);
    } else if (typeof value === 'string') {
      let dateArray = _.split(value, /[\-\/]/);
      if (dateArray.length === 1 || dateArray.length === 2 && _.isEmpty(dateArray[1])) {
        result = new Date(Date.parse('1/' + dateArray[0]));
      } else if (dateArray.length === 2 || dateArray.length === 3 && _.isEmpty(dateArray[2])) {
        result = new Date(Date.parse(dateArray[1] + '/' + dateArray[0]));
      } else {
        if (InternationalDateAdapter.isBEYear) {
          let year = (dateArray[2].length <= 2 ? parseInt('25' + dateArray[2]) : parseInt(dateArray[2])) - 543;
          result = new Date(Date.parse(dateArray[1] + '/' + dateArray[0] + '/' + year.toString()));
        } else {
          result = new Date(Date.parse(dateArray[1] + '/' + dateArray[0] + '/' + dateArray[2]));
        }
      }
    } else {
      result = new Date(Date.parse(value));
    }
    return result;  
  }

  override format(date: Date, displayFormat: Object): string {
    if (!this.isValid(date)) {
      throw Error('NativeDateAdapter: Cannot format invalid date.');
    }
    if (InternationalDateAdapter.isBEYear) {
      return date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() + 543);  
    } else {
      return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    }
  }
}