import { Injectable } from '@angular/core';

import { AuthenticationStatus } from 'src/definitions/user';

import { CognitoService } from 'src/services/cognito.service';


@Injectable({
  providedIn: 'root'
})
export class UserState {
  private static _authenticationStatus = AuthenticationStatus.SignedOut;
  private static _admin: boolean = false;
  private static _attribute: any | null = null;


  // Constructor ___________________________________________________________________________________

  constructor(private cognitoService: CognitoService) { }


  // Accessors _____________________________________________________________________________________

  //................................................................................................
  public get authenticationStatus(): AuthenticationStatus {
    return UserState._authenticationStatus;
  }

  //................................................................................................
  public get name(): string | null {
    return UserState._attribute !== null ? UserState._attribute.name : null;
  }

  //................................................................................................
  public get surname(): string | null {
    return UserState._attribute !== null ? UserState._attribute.surname : null;
  }

  //................................................................................................
  public get admin(): boolean {
    return UserState._admin;
  }


  // Public Methods ________________________________________________________________________________

  //................................................................................................
  public async reinstate() {
    UserState._authenticationStatus = await this.cognitoService.reinstate();
    UserState._attribute = this.cognitoService.getUserProfile();
    UserState._admin = UserState._attribute !== null && UserState._attribute.role === 'admin';
    return UserState._authenticationStatus;
  }

  //................................................................................................
  public async signIn(username: string, password: string) {
    UserState._authenticationStatus = await this.cognitoService.signIn(username, password);
    UserState._attribute = this.cognitoService.getUserProfile();
    UserState._admin = UserState._attribute !== null && UserState._attribute.role === 'admin';
    return UserState._authenticationStatus;
  }

  //................................................................................................
  public signOut() {
    this.cognitoService.signOut();
    UserState._authenticationStatus = AuthenticationStatus.SignedOut;
  }

  //................................................................................................
  public async completeNewPasswordChallenge(newPassword: string) {
    UserState._authenticationStatus = await this.cognitoService.completeNewPasswordChallenge(newPassword);
    return UserState._authenticationStatus;
  }
}