import * as AWS from 'aws-sdk/global';

import { Injectable } from '@angular/core';

import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

import { AuthenticationStatus } from 'src/definitions/user';
import { Fault, Exception } from 'src/definitions/exception';

const UserPoolId = 'ap-southeast-1_T0IkGjyux';
const ClientId = '7mf7ooiekmase7ujpgnv55ockc';


@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  private static userPoolData = {
    UserPoolId: UserPoolId,
    ClientId: ClientId,
    Storage: sessionStorage
  };
  private static userPool = new CognitoUserPool({
    UserPoolId: UserPoolId,
    ClientId: ClientId,
    Storage: sessionStorage
  });
  private static cognitoUserSession: CognitoUserSession | null = null;
  private static passwordChallengeParams: any = null;


  // Public Methods ________________________________________________________________________________

  //................................................................................................
  public reinstate(): Promise<AuthenticationStatus> {
    return new Promise((resolve, reject) => {
      try {
        AWS.config.region = 'ap-southeast-1';
        AWS.config.signatureVersion = 'v4';
        let cognitoUser = new CognitoUserPool(CognitoService.userPoolData).getCurrentUser();
        if (cognitoUser) {
          cognitoUser.getSession((error: Error, session: CognitoUserSession | null) => {
            if (error) {
              reject(error);
            } else {
              if (session) {
                CognitoService.cognitoUserSession = session;
                resolve(AuthenticationStatus.SignedIn);
              } else {
                resolve(AuthenticationStatus.SignedOut);
              }
            }
          });
        } else {
          resolve(AuthenticationStatus.SignedOut);
        }
      } catch(error) {
        console.log(error);
        reject(error);
      }
    });
  }

  //................................................................................................
  public signIn(username: string, password: string): Promise<AuthenticationStatus> {
    return new Promise((resolve, reject) => {
      let userPool = new CognitoUserPool(CognitoService.userPoolData);
      let cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
        Storage: sessionStorage
      });
      let authenDetails = new AuthenticationDetails({ Username: username, Password: password });
      cognitoUser.authenticateUser(authenDetails, {
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          CognitoService.passwordChallengeParams = {
            cognitoUser: cognitoUser,
            userAttributes: userAttributes
          };
          delete CognitoService.passwordChallengeParams.userAttributes.email;
          delete CognitoService.passwordChallengeParams.userAttributes.email_verified;
          resolve(AuthenticationStatus.NewPasswordRequired);
        },
        onSuccess: async (session) => {
          CognitoService.cognitoUserSession = session;
          resolve(AuthenticationStatus.SignedIn)
        },
        onFailure: (error) => {
          console.log('Authentication Error: ', error);
          if (error.code !== undefined) {
            switch(error.code) {
              case 'UserNotFoundException':
                reject(new Fault(Exception.UserDoesNotExist));
                break;
              case 'NotAuthorizedException':
                if (error.message === 'User is disabled') {
                  reject(new Fault(Exception.DisabledUser));
                } else {
                  reject(new Fault(Exception.InvalidUserIdOrPassword));
                }
                break;
              case 'InvalidParameterException':
                reject(new Fault(Exception.InvalidUserIdOrPasswordFormat));
                break;
              default:
                reject(new Fault(Exception.CannotAuthenticateUser, error));
            }
          } else {
            reject(new Fault(Exception.CannotAuthenticateUser, error));
          }
        }
      });
    });
  }

  //................................................................................................
  public signOut(): void {
    try {
      let cognitoUser = new CognitoUserPool(CognitoService.userPoolData).getCurrentUser();
      if (cognitoUser) {
        cognitoUser.signOut();
        CognitoService.cognitoUserSession = null;
        CognitoService.passwordChallengeParams = null;
      }  
    } catch(error) {
      throw new Fault(Exception.CannotSignOut, error);
    }
  }

  //................................................................................................
  public completeNewPasswordChallenge(newPassword: string): Promise<AuthenticationStatus> {
    return new Promise((resolve, reject) => {
      let cognitoUser = CognitoService.passwordChallengeParams.cognitoUser;
      if (cognitoUser) {
        cognitoUser.completeNewPasswordChallenge(newPassword, CognitoService.passwordChallengeParams.userAttributes, {
          onSuccess: (session: CognitoUserSession) => {
            CognitoService.cognitoUserSession = session;
            resolve(AuthenticationStatus.SignedIn);
          },
          onFailure: (error: Error) => {
            reject(error);
          }
        });
      } else {
        reject(new Fault(Exception.UserIsNotSignedIn));
      }  
    });
  }

  //................................................................................................
  public getUserProfile(): any {
    if (CognitoService.cognitoUserSession) {
      let payload = CognitoService.cognitoUserSession.getIdToken().decodePayload();
      return {
        userId: payload['cognito:username'],
        name: payload['given_name'],
        surname: payload['family_name'],
        email: payload['email'],
        role: payload['custom:role']
      };  
    } else {
      return null;
    }
  }

  //................................................................................................
  public refreshCredentials(): Promise<any> {
    return new Promise((resolve, reject) => {
      let result = new CognitoUserPool(CognitoService.userPoolData).getCurrentUser();
      if (result !== null) {
        let cognitoUser = result;
        cognitoUser.getSession((sessionError: Error, session: any) => {
          if (session) {
            let refreshToken = session.getRefreshToken();
            cognitoUser.refreshSession(refreshToken, (refreshSessionError, renewSession) => {
              if (refreshSessionError) {
                console.log('Error ' + refreshSessionError.code + ': ' + refreshSessionError.message);
                reject(refreshSessionError);
              } else {
                resolve('Success');
              }
            });
          } else
            reject(sessionError);
        })
      } else {
        resolve('No User');
      }
    });
  }

  //................................................................................................
  public changePassword(oldPassword: string, newPassword: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let result = new CognitoUserPool(CognitoService.userPoolData).getCurrentUser();
      if (result) {
        let cognitoUser: CognitoUser = result;
        cognitoUser.getSession((sessionError: Error, session: CognitoUserSession | null) => {
          if (session) {
            cognitoUser.changePassword(oldPassword, newPassword, (changePwdError: any, result: any) => {
              if (changePwdError) {
                console.log('Change Password Error: ', changePwdError);
                if (changePwdError.message === 'Incorrect username or password.') {
                  reject(new Fault(Exception.InvalidPassword));
                } else if (changePwdError.code === 'LimitExceededException') {
                  reject(new Fault(Exception.NumberOfPasswordChangedExceedLimit));
                } else {
                  reject(new Fault(Exception.CannotChangePassword, { cause: changePwdError }));
                }
              } else {
                resolve(result);
              }
            })
          } else {
            reject(new Fault(Exception.CannotChangePassword, { cause: sessionError}));
          }
        })
      } else {
        reject(new Fault(Exception.UserIsNotSignedIn));
      }
    });
  }

  //................................................................................................
  public getIdToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (CognitoService.cognitoUserSession) {
        resolve(CognitoService.cognitoUserSession.getIdToken().getJwtToken());
      } else {
        let cognitoUser = new CognitoUserPool(CognitoService.userPoolData).getCurrentUser();
        if (cognitoUser) {
          cognitoUser.getSession((error: Error, session: any) => {
            if (error) {
              reject(error);
            } else {
              resolve(session.getIdToken().getJwtToken());
            }
          });
        } else {
          resolve(null);
        }  
      }
    });
  }

  //................................................................................................
  public getCurrentUserName(): string | undefined {
    let cognitoUser = CognitoService.userPool.getCurrentUser();
    return cognitoUser ? cognitoUser.getUsername() : undefined;
  }
}