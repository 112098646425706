import { NgForm, FormGroupDirective, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export const NewPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const newPassword = control.get('newPassword');
  const confirmPassword = control.get('confirmPassword');

  if (newPassword !== null && confirmPassword !== null
    && newPassword.dirty && confirmPassword.dirty
    && newPassword.valid && confirmPassword.valid
    && newPassword.value !== confirmPassword.value) {
    return { 'confirmNotMatched': true };
  } else {
    return null;
  }
};

export class ConfirmValidPasswordMatcher implements ErrorStateMatcher {
  isErrorState(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control !== null && control.parent !== null && control.parent.invalid && control.touched;
  }
}