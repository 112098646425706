// Excel Styles ____________________________________________________________________________________

export const ThinBorder = { style: 'thin', color: { argb: 'FF757575' } };
export const Title1Style = {
  font: { name: 'Cordia New', size: 24 }
};
export const Title2Style = {
  font: { name: 'Cordia New', size: 18 }
};
export const Title3Style = {
  font: { name: 'Cordia New', size: 16 }
};
export const NormalStyle = {
  font: { name: 'Cordia New', size: 14 }
};
export const NumberStyle = {
  font: { name: 'Cordia New', size: 14 },
  numFmt: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'
};
export const TableHeaderStyle = {
  font: { name: 'Cordia New', size: 14, italic: true },
  alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFBBDEFB' }
  }
};
export const TableTotalHeaderStyle = {
  font: { name: 'Cordia New', size: 14, italic: true },
  alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFDD835' }
  }
}
export const TableRemainingHeaderStyle = {
  font: { name: 'Cordia New', size: 14, italic: true },
  alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFB8C00' }
  }
}
export const TableSubheaderTextStyle = {
  font: { name: 'Cordia New', size: 14, bold: true },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFB3E5FC' }
  }
};
export const TableSubheaderNumberStyle = {
  font: { name: 'Cordia New', size: 14, bold: true },
  numFmt: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)',
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFB3E5FC' }
  }
};
export const TableTextStyle = {
  font: { name: 'Cordia New', size: 14 },
  alignment: { vertical: 'top' },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder }
};
export const TableIndentTextStyle = {
  font: { name: 'Cordia New', size: 14 },
  alignment: { vertical: 'top', indent: 1 },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder }
};
export const TableTextCenterStyle = {
  font: { name: 'Cordia New', size: 14 },
  alignment: { vertical: 'top', horizontal: 'center' },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder }
};
export const TableTextRightStyle = {
  font: { name: 'Cordia New', size: 14 },
  alignment: { vertical: 'top', horizontal: 'right' },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder }
};
export const TableWrapTextStyle = {
  font: { name: 'Cordia New', size: 14 },
  alignment: { vertical: 'top', horizontal: 'left', wrapText: true },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder }
}
export const TableNumberStyle = {
  font: { name: 'Cordia New', size: 14 },
  numFmt: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)',
  alignment: { vertical: 'top' },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder }
};
export const TableFixedNumberStyle = {
  font: { name: 'Cordia New', size: 14 },
  numFmt: '#,##0.0',
  alignment: { vertical: 'top' },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder }
};
export const TableIntegerStyle = {
  font: { name: 'Cordia New', size: 14 },
  numFmt: '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)',
  alignment: { vertical: 'top' },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder }
};
export const TableDateStyle = {
  font: { name: 'Cordia New', size: 14 },
  numFmt: 'dd/mm/yy',
  alignment: { vertical: 'top', horizontal: 'center' },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder }
};
export const TableTimeStyle = {
  font: { name: 'Cordia New', size: 14 },
  numFmt: 'hh:mm',
  alignment: { vertical: 'top', horizontal: 'center' },
  border: { top: ThinBorder, left: ThinBorder, bottom: ThinBorder, right: ThinBorder }
};


// Access Log Styles _______________________________________________________________________________

export const Columns = [
  { width: 10 }, { width: 15 }, { width: 25 }, { width: 25 }
];
export const ColumnLabels = [
  { column: 'A', row: 4, label: 'เวลา' },
  { column: 'B', row: 4, label: 'รายงานของวันที่' },
  { column: 'C', row: 4, label: 'ชื่อ' },
  { column: 'D', row: 4, label: 'นามสกุล' }
];