<div class="pz-icon-dialog-header">
  <div class="pz-header-icon">
    <img src="./assets/icons/header-icons/confirm.svg">
  </div>

  <div class="pz-icon-header-toolbar">
    <button mat-icon-button color="accent"
      class="pz-icon-header-toolbar-button"
      [autofocus]="false"
      (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="pz-icon-header-title"></div>
</div>

<div class="pzl-dialog-panel">
  <div class="pzl-message" translate>{{data.message}}</div>

  <mat-radio-group [(ngModel)]="data.confirm" name="confirm">
    <mat-radio-button color="primary" value="yes">
      <span>ใช่</span>
    </mat-radio-button>
    <div style="width:48px"></div>
    <mat-radio-button color="primary" value="no">
      <span>ไม่ใช่</span>
    </mat-radio-button>
  </mat-radio-group> 

  <button mat-fab color="accent"
    (click)="confirm()">
    <mat-icon>done</mat-icon>
  </button>
</div>