import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';

import { Employee, OrganizationId, TimeAttendance } from 'src/definitions/data';

import { ApiService } from 'src/services/api';
import { Utility } from 'src/services/utility.service';

import { EmptyTime, ReportLayout, ReportStyles, TorStaffList } from './aec-attendance-report.definition';

const EmployeeFieldList = 'id, empId, nameTh, surnameTh, nameEn, surnameEn, startEmpDate, endEmpDate, title';


@Injectable()
export class AecAttendanceReport {


  // Constructor ___________________________________________________________________________________

  constructor(private api: ApiService) { }


  // Public Methods ________________________________________________________________________________

  //................................................................................................
  public async create(date: Date) {
    let employees = (await this.api.employee.scan(OrganizationId, EmployeeFieldList))
      .filter((employee: Employee) => this.isEmployeeActiveOnDate(employee, date));
    let attendances = await this.api.attendance.query(OrganizationId, date);
    return {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      pageMargins: [ 50, 72, 50, 72 ],
      content: [ this.defineBody(date, employees, attendances) ],
      styles: ReportStyles
    };
  }


  // Private Methods _______________________________________________________________________________

  //................................................................................................
  private defineBody(date: Date, employees: Array<Employee>, attendances: Array<TimeAttendance>) {
    let beDate =  `${date.getDate()}/${this.pad(date.getMonth() + 1)}/${date.getFullYear() + 543}`
    let tableDef = {
      style: 'default',
      table: {
        headerRows: 5,
        widths: [ 30, '*', 70, 70, 45, 35, 35 ],
        body: [
          ...this.defineTableHeader(date)
        ] as Array<any>
      },
      layout: ReportLayout
    };
    for (let idx = 0; idx < TorStaffList.length; idx++) {
      let staff = TorStaffList[idx];
      if (staff !== null) {
        let employee = _.find(employees, { empId: staff.id, title: staff.title });
        if (employee) {
          let attendance = _.find(attendances, [ 'id', employee.id ]) as TimeAttendance;
          let clockInTime = attendance ? attendance.clockIn.time : null;
          let clockOutTime = attendance ? attendance.clockOut.time : null;
          tableDef.table.body.push([
            { text: employee.empId, style: 'tableCellCenter', border: [ true, true, true, true ] },
            { text: employee.title, style: 'tableCellLeft', border: [ true, true, true, true ] },
            { text: employee.nameTh, style: 'tableCellLeft', border: [ true, true, true, true ] },
            { text: employee.surnameTh, style: 'tableCellLeft', border: [ true, true, true, true ] },
            { text: beDate, style: 'tableCellCenter', border: [ true, true, true, true ] },
            { text: clockInTime ? moment(clockInTime).format('HH:mm') : EmptyTime, style: 'tableCellCenter', border: [ true, true, true, true ] },
            { text: clockOutTime ? moment(clockOutTime).format('HH:mm') : EmptyTime, style: 'tableCellCenter', border: [ true, true, true, true ] }
          ]);
        }  
      } else {
        tableDef.table.body.push([
          { text: ' ', style: 'tableCellCenter', border: [ true, true, true, true ] },
          { text: ' ', style: 'tableCellLeft', border: [ true, true, true, true ] },
          { text: ' ', style: 'tableCellLeft', border: [ true, true, true, true ] },
          { text: ' ', style: 'tableCellLeft', border: [ true, true, true, true ] },
          { text: ' ', style: 'tableCellCenter', border: [ true, true, true, true ] },
          { text: ' ', style: 'tableCellCenter', border: [ true, true, true, true ] },
          { text: ' ', style: 'tableCellCenter', border: [ true, true, true, true ] }
        ])
      }
    }
    return tableDef;
  }

  //................................................................................................
  private defineTableHeader(date: Date): Array<any> {
    return [
      [
        { text: 'ข้อมูลเวลาเข้า-ออก', style: 'title1', colSpan: 7 },
        {}, {}, {}, {}, {}, {}
      ],
      [
        { text: `วันที่ ${Utility.dateFormat(date, 'FFF')}`, style: 'title2', colSpan: 7 },
        {}, {}, {}, {}, {}, {}
      ],
      [
        { text: ' ', style: 'titlePadding' , colSpan: 7 },
        {}, {}, {}, {}, {}, {}
      ],
      [
        { text: 'รหัสตาม TOR', style: 'tableHeader', rowSpan: 2, border: [ true, true, true, true ] },
        { text: 'ตำแหน่ง', style: 'tablePaddedHeader', rowSpan: 2, border: [ true, true, true, true ] },
        { text: 'ชื่อ', style: 'tablePaddedHeader', rowSpan: 2, border: [ true, true, true, true ] },
        { text: 'นามสกุล', style: 'tablePaddedHeader', rowSpan: 2, border: [ true, true, true, true ] },
        { text: 'วันที่', style: 'tablePaddedHeader', rowSpan: 2, border: [ true, true, true, true ] },
        { text: 'เวลา', style: 'tableHeader', colSpan: 2, border: [ true, true, true, true ] },
        {}
      ],
      [
        {}, {}, {}, {}, {},
        { text: 'เข้า', style: 'tableHeader', border: [ true, true, true, true ] },
        { text: 'ออก', style: 'tableHeader', border: [ true, true, true, true ] }
      ]
    ] as Array<any>;
  }

  //................................................................................................
  private isEmployeeActiveOnDate(employee: Employee, date: Date) {
    let isStartEmploymentDateNotEmpty = employee.startEmpDate !== undefined && employee.startEmpDate !== null;
    let isEndEmploymentDateNotEmpty = employee.endEmpDate !== undefined && employee.endEmpDate !== null;
    let isEmploymentStartBeforeDate = isStartEmploymentDateNotEmpty ? moment(employee.startEmpDate).isSameOrBefore(date, 'day') : true;
    let isEmploymentEndAfterDate = isEndEmploymentDateNotEmpty ? moment(employee.endEmpDate).isSameOrAfter(date, 'day') : true;
    return isEmploymentStartBeforeDate && isEmploymentEndAfterDate;
  }

  //................................................................................................
  private pad(value: number): string {
    return value > 9 ? `${value}` : `0${value}`;
  }
}