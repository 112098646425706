import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Fault, Exception } from 'src/definitions/exception';

import { CognitoService } from 'src/services/cognito.service';
import { Utility } from 'src/services/utility.service';

import { API } from './api-key';


@Injectable({
  providedIn: 'root',
})
export class ApiService {


  // Constructor ___________________________________________________________________________________

  constructor(private httpClient: HttpClient,
    private cognitoService: CognitoService) { }


  // Attendance API's ______________________________________________________________________________

  //................................................................................................
  public readonly attendance = {
    query: async (organizationId: string, date: Date) => {
      return JSON.parse(await this.invoke(API.Attendance.Query, {
        organizationId: organizationId,
        date: date
      }), Utility.dateParser);
    }
  }


  // Employee API's ________________________________________________________________________________

  //................................................................................................
  public readonly employee = {
    scan: async (organizationId: string, fieldList: string) => {
      return JSON.parse(await this.invoke(API.Employee.Scan, {
        organizationId: organizationId,
        fieldList: fieldList
      }), Utility.dateParser);
    }
  }


  // Logger API's __________________________________________________________________________________
  public readonly logger = {
    get: async (date: Date): Promise<Array<any> | null> => {
      let result = await this.invoke(API.Logger.Get, {
        date: date
      });
      return result ? JSON.parse(result, Utility.dateParser) : null;
    },
    put: async (date: Date, reportDate: string, name: string | null, surname: string | null) => {
      await this.invoke(API.Logger.Put, {
        date: date,
        reportDate: reportDate,
        name: name,
        surname: surname
      });
    }
  }



  // Private Methods _______________________________________________________________________________

  //................................................................................................
  private invoke(api: string, payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this.httpClient !== null) {
        let idToken = await this.cognitoService.getIdToken();
        let options = { headers: new HttpHeaders({ Authorization: idToken }) };
        this.httpClient.post(api, payload, options).subscribe({
          next: (response: any) => {
            if (response && response.errorMessage) {
              reject(new Error(response.errorMessage));
            } else {
              resolve(response);
            }
          },
          error: (error: Error) => {
            console.log('API: ', api);
            console.log('Payload: ', payload);
            console.log('Error: ', error);
            reject(new Fault(Exception.CannotEstablishAPICall, { cause: error }));
          }
        });
      } else {
        reject(new Fault(Exception.CannotEstablishAPICall));
      }
    });
  }
}