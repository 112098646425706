<div class="pzl-background">
</div>

<div class="pzl-input-panel">
  <div class="pzl-title">Sign in</div>

  <ng-container *ngIf="!passwordChallenge">
    <form [formGroup]="signInForm" (ngSubmit)="signIn()">
      <div class="pzl-input">
        <div>ชื่อผู้ใช้</div>
        <input type="text" formControlName="username" [readonly]="isInputDisabled" required autofocus>
        <p>
          <span *ngIf="username.dirty && username.hasError('required')">
            กรุณาใส่รหัสผู้ใช้
          </span>
          <span *ngIf="username.hasError('space')">
            ชื่อผู้ใช้ต้องไม่มีเว้นวรรค
          </span>
        </p>
      </div>
  
      <div class="pzl-input">
        <div>รหัสผ่าน</div>
        <input type="password" formControlName="password" [readonly]="isInputDisabled" required>
        <p>
          <span *ngIf="password.dirty && password.hasError('required')">
            กรุณาใส่รหัสผ่าน
          </span>
          <span *ngIf="password.dirty && password.hasError('space')">
            รหัสผ่านต้องไม่มีเว้นวรรค
          </span>
          <span *ngIf="password.dirty && !password.hasError('space') && password.hasError('minlength')">
            รหัสผ่านต้องมีทั้งตัวอักษรและตัวเลขอย่างน้อย 8 ตัวอักษร
          </span>
        </p>
      </div>

      <div class="pzl-signin-footer">
        <ng-container *ngIf="busy; then showSpinner; else showButton"></ng-container>
  
        <ng-template #showButton>
          <button type="submit" mat-fab class="pzl-signin-fab">
            <img src="/assets/images/Icon56px.png">
          </button>
        </ng-template>
  
        <ng-template #showSpinner>
          <div class="pzl-spinner-wrapper">
            <img src="/assets/images/Icon56px.png">
            <mat-progress-spinner mode="indeterminate" diameter="64" strokeWidth="3"
              class="pzl-spinner">
            </mat-progress-spinner>
          </div>
        </ng-template>
      </div>
    </form>    
  </ng-container>

  <ng-container *ngIf="passwordChallenge">
    <form [formGroup]="passwordChallengeForm" (ngSubmit)="completePasswordChallenge()">
      <div class="pzl-input">
        <div>รหัสผ่านใหม่</div>
        <input #newPasswordInp type="password" formControlName="newPassword" [readonly]="isInputDisabled" required>
        <p>
          <span *ngIf="newPassword.dirty && newPassword.hasError('required')">
            กรุณาใส่รหัสผ่าน
          </span>
          <span *ngIf="newPassword.dirty && newPassword.hasError('space')">
            รหัสผ่านต้องไม่มีเว้นวรรค
          </span>
          <span *ngIf="newPassword.dirty && !newPassword.hasError('space') && newPassword.hasError('minlength')">
            รหัสผ่านต้องมีทั้งตัวอักษรและตัวเลขอย่างน้อย 8 ตัวอักษร
          </span>
        </p>
      </div>  
      <div class="pzl-input">
        <div>ยืนยันรหัสผ่านใหม่</div>
        <input matInput type="password" formControlName="confirmPassword" [readonly]="isInputDisabled" [errorStateMatcher]="confirmValidPasswordMatcher" required>
        <p>
          <span *ngIf="confirmPassword.dirty && confirmPassword.hasError('required')">
            กรุณาใส่ยืนยันรหัสผ่าน
          </span>
          <span *ngIf="confirmPassword.dirty && confirmPassword.hasError('space')">
            รหัสผ่านต้องไม่มีเว้นวรรค
          </span>
          <span *ngIf="confirmPassword.dirty && !confirmPassword.hasError('space') && confirmPassword.hasError('minlength')">
            รหัสผ่านต้องมีทั้งตัวอักษรและตัวเลขอย่างน้อย 8 ตัวอักษร
          </span>
          <span *ngIf="passwordChallengeForm.errors?.['confirmNotMatched']">
            รหัสผ่านที่ยืนยันไม่ตรงกับรหัสผ่าน
          </span>    
        </p>
      </div>

      <div class="pzl-signin-footer">
        <ng-container *ngIf="busy; then showSpinner; else showButton"></ng-container>
  
        <ng-template #showButton>
          <button type="submit" mat-fab class="pzl-signin-fab">
            <img src="/assets/images/Icon56px.png">
          </button>
        </ng-template>
  
        <ng-template #showSpinner>
          <div class="pzl-spinner-wrapper">
            <img src="/assets/images/Icon56px.png">
            <mat-progress-spinner mode="indeterminate" diameter="64" strokeWidth="3"
              class="pzl-spinner">
            </mat-progress-spinner>
          </div>
        </ng-template>
      </div>
    </form>
  </ng-container>
</div>

<div class="pzl-version">
  {{version}}
</div>

<div class="pzl-account-menu">
  <button mat-icon-button [matMenuTriggerFor]="accountMenu">
    <mat-icon svgIcon="ionicon_person_circle" class="pzl-account-icon"></mat-icon>
  </button>
  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item (click)="showForgotPasswordDialog()">
      <mat-icon svgIcon="ionicon_alert" color="primary"></mat-icon>
      <span>ลืมรหัสผ่าน</span>
    </button>
    <button mat-menu-item (click)="showVerifyUserDialog()">
      <mat-icon svgIcon="ionicon_shield_checkmark" color="primary"></mat-icon>
      <span>ป้อนรหัสยืนยัน</span>
    </button>
  </mat-menu>
</div>