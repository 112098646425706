import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { MatIconRegistry } from '@angular/material/icon';

const CustomIcons = [
  { name: 'ionicon_alert', url: 'assets/icons/ionic/alert-outline.svg' },
  { name: 'ionicon_log_out', url: 'assets/icons/ionic/log-out-outline.svg' },
  { name: 'ionicon_person_circle', url: 'assets/icons/ionic/person-circle-outline.svg' },
  { name: 'ionicon_print', url: 'assets/icons/ionic/print-outline.svg' },
  { name: 'ionicon_shield_checkmark', url: 'assets/icons/ionic/shield-checkmark-outline.svg' },

  { name: 'zi_file_download', url: 'assets/icons/zi/zi-file-download.svg' }
];


@NgModule({
})
export class CustomIconsModule {

  // Constructor ___________________________________________________________________________________
  
  constructor (private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    CustomIcons.forEach(icon => {
      let safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(icon.url);
      this.iconRegistry.addSvgIcon(icon.name, safeUrl);
    });
  }

}