import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';

import { ConfirmationDialog } from 'src/dialogs/confirmation-dialog';
import { SnackBar } from 'src/snack-bar';

import { UnauthenticatedAPI } from '../unauthenticated.api';

const BangkokPhoneNumberMask = '00-000-0000';
const RuralPhoneNumberMask = '000-000-000';
const MobilePhoneNumberMask = '000-000-0000';


@Component({
  selector: 'prozper-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent {
  public phoneNumberMask = MobilePhoneNumberMask;
  public forgotPasswordForm = new UntypedFormGroup({
    username: new UntypedFormControl(null, [ Validators.required ])
  });


  // Constructor ___________________________________________________________________________________

  constructor(private dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    private snackBar: SnackBar,
    private confirmationDialog: ConfirmationDialog) { }


  // Accessors _____________________________________________________________________________________

  //................................................................................................
  public get username(): UntypedFormControl {
    return this.forgotPasswordForm.get('username') as UntypedFormControl;
  }

  //................................................................................................
  public get submitDisabled(): boolean {
    return this.forgotPasswordForm.pristine || this.forgotPasswordForm.dirty && this.forgotPasswordForm.invalid;
  }


  // View Controllers ______________________________________________________________________________

  //................................................................................................
  public close() {
    this.dialogRef.close();
  }

  //................................................................................................
  public async forgotPassword() {
    if (this.forgotPasswordForm.valid) {
      let confirm = await this.confirmationDialog.open('ต้องการขอรหัสยืนยันเนื่องจากลืมรหัสผ่านใช่หรือไม่', 'no');
      if (confirm) {
        try {
          let response: any = await UnauthenticatedAPI.user.forgotPassword.initiate(this.username.value);
          let message = 'ส่งรหัสยืนยันให้ทางอีเมล์ ' + response.CodeDeliveryDetails.Destination +
            ' แล้ว ท่านสามารถนำรหัสยืนยันมาใช้เปลี่ยนรหัสผ่านจากหน้าจอป้อนรหัสผ่าน';
          this.snackBar.notify(message);
          this.dialogRef.close();
        } catch(error) {
          console.log('Error: ', error);
          this.snackBar.alert(error as Error);
        }
      }
    }
  }

  //................................................................................................
  public assignPhoneNumberMask(value: string) {
    if (value) {
      let areaCode = value.substr(0, 2);
      switch(areaCode) {
        case '02':
          this.phoneNumberMask = BangkokPhoneNumberMask;
          break;
        case '03':
        case '04':
        case '05':
        case '07':
          this.phoneNumberMask = RuralPhoneNumberMask;
          break;
        default:
          this.phoneNumberMask = MobilePhoneNumberMask;
          break;
      }  
    }
  }
}