import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';

import { TranslateModule } from '@ngx-translate/core';

import { ConfirmationDialog } from './confirmation-dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';


@NgModule({
    imports: [
        CommonModule, FormsModule,
        MatButtonModule, MatDialogModule, MatIconModule, MatRadioModule,
        TranslateModule
    ],
    declarations: [
        ConfirmationDialogComponent
    ],
    providers: [
        ConfirmationDialog
    ]
})
export class ConfirmationDialogModule {}