<ng-container *ngIf="signedIn; then appMain; else signIn"></ng-container>

<ng-template #signIn>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #appMain>
  <div class="pzl-canvas">
    <mat-toolbar>
      <img src="./assets/images/proZper-logo.png" alt="proZper Logo">

      <div class="pzl-filler"></div>

      <button mat-icon-button color="accent" matTooltip="ออกจากระบบ"
        (click)="toggleUserMenu()">
        <mat-icon svgIcon="ionicon_log_out" class="pzl-account-icon"></mat-icon>
      </button>  
    </mat-toolbar>   

    <div class="pzl-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>