import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgxMaskModule } from 'ngx-mask';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SignInModule } from 'src/app/signin/signin.module';
import { ReportDownloadModule } from 'src/app/report-download/report-download.module';
import { CustomIconsModule } from 'src/custom-icons/custom-icons.module';
import { SnackBarModule } from 'src/snack-bar/snack-bar.module';

import { AppComponent } from './app.component';
import { SignInComponent } from 'src/app/signin/signin.component';
import { ReportDownloadComponent } from 'src/app/report-download/report-download.component';

export const routes: Routes = [
  { path: '', redirectTo: '/signin', pathMatch: 'full' },

  { path: 'signin', component: SignInComponent },
  { path: 'report-download', component: ReportDownloadComponent },
];

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),

    MatButtonModule, MatIconModule, MatToolbarModule, MatTooltipModule,

    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      },
      defaultLanguage: 'th'
    }),

    SignInModule, ReportDownloadModule,
    CustomIconsModule, SnackBarModule,
  ],
  providers: [],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
