export enum Salutation {
  Mister,
  Mrs,
  Miss,
  ML,
  MR,
  MC,
  ActingSubLt,
  ActingSubLtWRTA
};

export enum Religion {
  Buddhism,
  Christianity,
  Islam,
  Hinduism,
  Sikhism,
  Other
};

export enum MaritalStatus {
  Single,
  Married,
  Divorced,
  Widowed,
  Separated
};

export enum EmploymentStatus {
  Probation,
  Permanent,
  Resigned
};

export enum EmploymentType {
  FullTime,
  PartTime,
  Daily
};

export enum TaxCalculation {
  WithHeld, // หัก ณ ที่จ่าย
  LumSum,   // เหมา
  None      // ไม่ต้องคำนวณ
};

export enum TaxSubmission {
  HardCopy,
  Internet
};

export enum TaxMaritalStatus {
  Single,                 // โสด
  MarriedThroughTaxYear,  // สมรสตลอดปีภาษี
  MarriedDuringTaxYear,   // สมรสระหว่างปีภาษี
  DivorcedDuringTaxYear,  // หย่าระหว่างปีภาษี
  WidowedDuringTaxYear    // หม้ายระหว่างปีภาษี
};

export enum SprouseIncome {
  Included,   // มีเงินได้ร่วมคำนวณภาษี
  Separated,  // มีเงินได้แยกคำนวณภาษี
  NoIncome    // ไม่มีเงินได้
};

export enum BankAccountType {
  Saving,
  Checking
};

export interface Address {
  streetTh: string;
  subDistTh: string;
  districtTh: string;
  provinceTh: string;
  postalCode: string;
  streetEn: string;
  subDistEn: string;
  districtEn: string;
  provinceEn: string;
};

export interface TaxItem {
  description: string;
  amount: number;
};

export interface WorkRecord {
  title: string;
  effDate: Date;
  lastDate: Date;
  orgUnit: string;
  salary: number;
};

export interface BankAccount {
  code: string;
  bank?: string;
  branch: string;
  accNo: string;
  accType: BankAccountType;
};

export interface EmploymentRecord {
  start: Date;
  end: Date;
};

export interface Employee {
  id: string;
  empId: string;
  salutation: Salutation;
  nameTh: string;
  surnameTh: string;
  nameEn: string;
  surnameEn: string;
  nickname: string;
  passportNo?: string;
  citizenId: string;
  birthDate: Date;
  idExpDate: Date;
  nationality: string;
  religion?: Religion;
  mariStatus: MaritalStatus;
  regAddress?: Address;
  useRegAddr?: boolean;
  curAddress?: Address;
  phone?: string;
  email?: string;
  emerContact?: {
    name: string,
    relation: string,
    phone: string,
    email: string,
    address:  string
  };
  bankAcc: BankAccount;
  transferBank: number;
  picture?: string;
  empStatus: EmploymentStatus;
  title: string;
  type: EmploymentType;
  startEmpDate: Date;
  endEmpDate: Date;
  employmentHistory?: Array<EmploymentRecord>;
  currentWork: WorkRecord;
  workRecords?: Array<WorkRecord>;
  socSecurity: {
    member: boolean,
    selfPaid: boolean,
    id: string,
    hospital: string
  };
  taxCalc?: TaxCalculation;
  taxSubmit?: TaxSubmission;
  taxMariStatus?: TaxMaritalStatus;
  taxSprouseCalc?: SprouseIncome;
  taxExempts: Array<TaxItem>;
  taxDeducts: Array<TaxItem>;
};