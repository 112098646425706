import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationStatus } from 'src/definitions/user';
import {
  whitespaceValidator,
  NewPasswordValidator, ConfirmValidPasswordMatcher
} from 'src/definitions/validation';
import { Exception, Fault } from 'src/definitions/exception';

import { CognitoService } from 'src/services/cognito.service';

import { UserState } from 'src/user-state';

import { SnackBar } from 'src/snack-bar';

import { ForgotPasswordDialog } from '../signin/forgot-password.dialog';
import { VerifyUserDialog } from '../signin/verify-user.dialog';
import {  } from 'src/definitions/validation';


@Component({
  selector: 'prozper-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  providers: [ ForgotPasswordDialog, VerifyUserDialog ]
})
export class SignInComponent implements OnInit {
  @ViewChild('newPasswordInp') newPasswordElement: ElementRef | null = null;

  public busy = false;
  public isInputDisabled: boolean = false;
  public version = 'AOT';
  public signInForm = new UntypedFormGroup({
    username: new UntypedFormControl(null, [ Validators.required, whitespaceValidator() ]),
    password: new UntypedFormControl(null, [ Validators.required, Validators.minLength(8), whitespaceValidator() ])
  });
  public passwordChallengeForm = new UntypedFormGroup({
    newPassword: new UntypedFormControl(null, [ Validators.required, Validators.minLength(8), whitespaceValidator() ]),
    confirmPassword: new UntypedFormControl(null, [ Validators.required, Validators.minLength(8), whitespaceValidator() ])
  }, { validators: NewPasswordValidator });
  public confirmValidPasswordMatcher = new ConfirmValidPasswordMatcher();

  
  // Constructor ___________________________________________________________________________________

  constructor(private router: Router,
    private user: UserState,
    private cognitoService: CognitoService,
    private forgotPasswordDialog: ForgotPasswordDialog,
    private verifyUserDialog: VerifyUserDialog,
    private snackBar: SnackBar) { }


  // Lifecycle Hooks _______________________________________________________________________________

  public async ngOnInit() {
    this.busy = false;
    try {
      this.cognitoService.signOut();
    } catch(error) {
      this.snackBar.alert(error as Error);
    }
  }


  // Accessors _____________________________________________________________________________________

  //................................................................................................
  public get username(): UntypedFormControl {
    return this.signInForm.get('username') as UntypedFormControl;
  }

  //................................................................................................
  public get password(): UntypedFormControl {
    return this.signInForm.get('password') as UntypedFormControl;
  }

  //................................................................................................
  public get newPassword(): UntypedFormControl {
    return this.passwordChallengeForm.get('newPassword') as UntypedFormControl;
  }

  //................................................................................................
  public get confirmPassword(): UntypedFormControl {
    return this.passwordChallengeForm.get('confirmPassword') as UntypedFormControl;
  }

  //................................................................................................
  public get passwordChallenge(): boolean {
    return this.user.authenticationStatus === AuthenticationStatus.NewPasswordRequired;
  }


  // View Controllers ______________________________________________________________________________

  //................................................................................................
  public async signIn() {
    this.snackBar.dismiss();
    if (this.signInForm.valid) {
      this.busy = true;
      try {
        let authenticationStatus = await this.user.signIn(this.username.value, this.password.value);
        if (authenticationStatus === AuthenticationStatus.NewPasswordRequired) {
          this.busy = false;
          setTimeout(() => {
            if (this.newPasswordElement !== null) {
              this.newPasswordElement.nativeElement.focus();
            }
          }, 200);
        } else {
          this.router.navigate([ '/report-download' ]);
        }
      } catch(error) {
        console.log('Error: ', error);
        this.busy = false;
        this.snackBar.alert(error as Error);
      }    
    } else {
      this.snackBar.warn('กรุณาใส่ข้อมูลให้ครบถ้วนและถูกต้อง');
    }
  }

  //................................................................................................
  public async completePasswordChallenge() {
    this.snackBar.dismiss();
    if (this.passwordChallengeForm.valid) {
      this.busy = true;
      try {
        await this.user.completeNewPasswordChallenge(this.newPassword.value);
        this.router.navigate([ '/report-download' ]);
      } catch(error) {
        console.log('Error: ', error);
        this.busy = false;
        this.snackBar.alert(error as Error);
      }  
    } else {
      this.snackBar.warn('กรุณาใส่ข้อมูลให้ครบถ้วนและถูกต้อง');
    }
  }

  //................................................................................................
  public showForgotPasswordDialog() {
    this.forgotPasswordDialog.present();
  }

  //................................................................................................
  public showVerifyUserDialog() {
    this.verifyUserDialog.present();
  }
}