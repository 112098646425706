const AotApiGateway = 'https://vkojpqbiy9.execute-api.ap-southeast-1.amazonaws.com/prod';

export const API = {
  Attendance: {
    Query: `${AotApiGateway}/attendance/query`
  },
  Employee: {
    Scan: `${AotApiGateway}/employee/scan`
  },
  Logger: {
    Get: `${AotApiGateway}/logger/get`,
    Put: `${AotApiGateway}/logger/put`
  }
};