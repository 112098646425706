import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { ReportDateDialogComponent } from './report-date-dialog.component';


@Injectable()
export class ReportDateDialog {


  // Constructor ___________________________________________________________________________________

  constructor(private dialog: MatDialog) { }


  // Public Methods ________________________________________________________________________________

  //................................................................................................
  public present(): Promise<Date> {
    return new Promise((resolve) => {
      this.dialog.open(ReportDateDialogComponent, {
        width: '320px',
        panelClass: 'pz-dialog-container'
      })
      .afterClosed()
      .subscribe((reportDate) => resolve(reportDate));
    })
  }
}