import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { SnackBarComponent } from './snack-bar.component';


@Injectable()
export class SnackBar {
  private snackBarRef: MatSnackBarRef<SnackBarComponent> | null = null;


  // Constructor ___________________________________________________________________________________
  constructor(private snackBar: MatSnackBar) { }

  // Public Methods ________________________________________________________________________________

  //................................................................................................
  public inform(messages: string | Array<string>, duration = 5000, showCloseButton = false) {
    this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
      duration: duration,
      horizontalPosition: 'right',
      panelClass: ['pz-information-snackbar'],
      data: { messages: messages, showCloseButton: showCloseButton }
    });    
  }

  //................................................................................................
  public notify(messages: string | Array<string>, duration = 5000, showCloseButton = false) {
    this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
      duration: duration,
      horizontalPosition: 'right',
      panelClass: ['pz-success-snackbar'],
      data: { messages: messages, showCloseButton: showCloseButton }
    });
  }

  //................................................................................................
  public warn(messages: string | Array<string>, duration = 5000, showCloseButton = false) {
    this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
      duration: duration,
      horizontalPosition: 'right',
      panelClass: ['pz-warning-snackbar'],
      data: { messages: messages, showCloseButton: showCloseButton }
    });
  }

  //................................................................................................
  public alert(messages: string | Array<string> | Error, duration = 9000, showCloseButton = true) {
    this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
      duration: duration,
      horizontalPosition: 'right',
      panelClass: ['pz-error-snackbar'],
      data: { messages: messages, showCloseButton: showCloseButton }
    });
  }

  //................................................................................................
  public dismiss() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
      this.snackBarRef = null;
    }
  }
}