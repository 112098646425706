import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { NgxMaskModule } from 'ngx-mask';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ConfirmationDialogModule } from 'src/dialogs/confirmation-dialog';

import { UnauthenticatedAPI } from './unauthenticated.api';
import { SignInComponent } from './signin.component';
import { ForgotPasswordDialogComponent } from './forgot-password.dialog';
import { VerifyUserDialogComponent } from './verify-user.dialog';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};


@NgModule({
  imports: [
    CommonModule, HttpClientModule, FormsModule, ReactiveFormsModule,
    // RouterModule.forChild([
    //   { path: '', component: SignInComponent }
    // ]),
    MatButtonModule, MatDialogModule, MatFormFieldModule, MatIconModule,
    MatInputModule, MatMenuModule, MatProgressSpinnerModule,

    NgxMaskModule.forChild(),
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      },
      defaultLanguage: 'th'
    }),

    ConfirmationDialogModule
  ],
  declarations: [
    SignInComponent,
    ForgotPasswordDialogComponent, VerifyUserDialogComponent
  ],
  providers: [
    UnauthenticatedAPI,

    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }
  ]
})
export class SignInModule {
  // Constructor ___________________________________________________________________________________

  constructor(private unauthenticatedAPI: UnauthenticatedAPI) { }
}