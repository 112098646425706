import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmationDialogConfig } from './confirmation-dialog.config';

@Component({
  selector: 'prozper-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {


  // Constructor ___________________________________________________________________________________

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogConfig,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>) { }


  // View Controllers ______________________________________________________________________________

  //................................................................................................
  public confirm() {
    this.dialogRef.close(this.data.confirm === 'yes');
  }

  //................................................................................................
  public close() {
    this.dialogRef.close(false);
  }
}