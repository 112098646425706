import { Component, Inject } from '@angular/core';

import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { TranslateService } from '@ngx-translate/core';

import { ErrorMessageKeyRex, NotificationMessageKeyRex } from 'src/definitions/validation';


@Component({
  selector: 'snack-bar-component',
  templateUrl: 'snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
  isHtml = false;
  isText = false;
  isList = false;
  messages: any;
  showCloseButton: boolean;


  // Constructor ___________________________________________________________________________________

  constructor(private snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private translate: TranslateService) {
    if (this.data.messages instanceof Error) {
      this.isText = true;
      this.isHtml = false;
      this.isList = false;
      if (ErrorMessageKeyRex.test(this.data.messages.message)) {
        this.messages = this.translate.instant(this.data.messages.message);
      } else {
        this.messages = `${this.translate.instant('Error.ApplicationError')} - ${this.data.messages.message}`;
      }
    } else if (typeof this.data.messages === 'string') {
      if (NotificationMessageKeyRex.test(this.data.messages)) {
        this.isText = true;
        this.isHtml = false;
        this.isList = false;
        this.messages = this.translate.instant(this.data.messages);
      } else {
        if (this.data.messages.charAt(0) === '<') {
          this.isHtml = true;
          this.isText = false;
        } else {
          this.isHtml = false;
          this.isText = true;
        }  
        this.isList = false;
        this.messages = this.data.messages;
      }
    } else if (Array.isArray(this.data.messages)) {
      this.isHtml = false;
      if (this.data.messages.length === 1) {
        this.isText = true;
        this.isList = false;
        this.messages = this.data.messages[0];
      } else {
        this.isText = false;
        this.isList = true;
        this.messages = this.data.messages;  
      }
    } else {
      this.isText = true;
      this.isList = false;
      this.isHtml = false;
      this.messages = '';
    }
    this.showCloseButton = data.showCloseButton;
  }


  // View Controllers ______________________________________________________________________________

  //................................................................................................
  public close() {
    this.snackBarRef.dismiss();
  }
}