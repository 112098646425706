import { AbstractControl } from '@angular/forms';


export function whitespaceValidator() {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === undefined || control.value === null || control.value.length === 0) {
      return null;
    } else {
      return /\s/g.test(control.value) ? { space: { value: control.value } } : null;
    }
  }
}