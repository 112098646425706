import { Injectable } from '@angular/core';

import {
  Locale,
  AbbrMonthNameTh, FullMonthNameTh,
  AbbrMonthNameEn, FullMonthNameEn
} from '../definitions/locale';
import { ISODateRex } from 'src/definitions/validation';


@Injectable()
export class Utility {


  // Public Methods ________________________________________________________________________________

  //................................................................................................
  public static dateParser(key: any, value: any) {
    return typeof value === 'string' && ISODateRex.test(value) ? new Date(value) : value;
  }

  //................................................................................................
  public static dateFormat(date: Date | string, format = 'SSS', locale = Locale.Thai, nullDate = null) {
    if (typeof(date) === 'string') {
      date = new Date(date);
    }
    if (date) {
      let year: string;
      let month: string;
      switch (format.charAt(2)) {
        case 'b' :
          year = (date.getFullYear() + 543).toString().substring(2);
          break;
        case 'B' :
          year = (date.getFullYear() + 543).toString();
          break;
        case 'a' :
        case 'S' :
          year = date.getFullYear().toString().substring(2);
          break;
        case 'A' :
        case 'F' :
        default:
          year = date.getFullYear().toString();
          break;
      }
      if (locale === Locale.Thai) {
        month = format.charAt(1) == 'S' ? AbbrMonthNameTh[date.getMonth()] : FullMonthNameTh[date.getMonth()];
      } else {
        month = format.charAt(1) == 'S' ? AbbrMonthNameEn[date.getMonth()] : FullMonthNameEn[date.getMonth()];
      }
      let day = date.getDate().toString();
      return `${day} ${month} ${year}`;
    } else {
      return nullDate;
    }
  }
}