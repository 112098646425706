export const ColumnSpanFiller = {};
export const RowSpanFiller = {};
export const EmptyTime = '-';

export const ReportLayout = {
  hLineWidth: (idx: number, node: any) => 0.7,
  vLineWidth: (idx: number, node: any) => 0.7,
  hLineColor: () => '#000000',
  vLineColor: () => '#000000',
  defaultBorder: false,
  paddingLeft: () => 1,
  paddingRight: () => 1,
  paddingTop: () => 0.75,
  paddingBottom: () => -1.5
};

export const ReportStyles = {
  default: { font: 'sarabun', fontSize: 11 },
  title1: { font: 'sarabun', fontSize: 18 },
  title2: { font: 'sarabun', fontSize: 14 },
  titlePadding: { font: 'sarabun', fontSize: 6 },
  tableHeader: { font: 'sarabun', fontSize: 11, alignment: 'center' },
  tablePaddedHeader: { font: 'sarabun', fontSize: 11, alignment: 'center', margin: [ 0, 8, 0, 0 ] },
  tableCellLeft: { font: 'sarabun', fontSize: 11, alignment: 'left' },
  tableCellCenter: { font: 'sarabun', fontSize: 11, alignment: 'center' },
  tableCellRight: { font: 'sarabun', fontSize: 11, alignment: 'right' }
};

export const TorStaffList = [
  { id: '1',    title: 'ผู้จัดการโครงการ' },
  { id: '2',    title: 'รองผู้จัดการโครงการ' },
  { id: '3-1',  title: 'วิศวกรโครงการ งานโยธา - 1' },
  { id: '3-2',  title: 'วิศวกรโครงการ งานโยธา - 2 (กลุ่มงานที่ 2)' },
  { id: '3-3',  title: 'วิศวกรโครงการ งานโยธา - 3' },
  { id: '4-1',  title: 'รองวิศวกรโครงการ งานโยธา - 1 (กลุ่มงานที่ 1)' },
  { id: '4-2',  title: 'รองวิศวกรโครงการ งานโยธา - 2 (กลุ่มงานที่ 2)' },
  { id: '4-3',  title: 'รองวิศวกรโครงการ งานโยธา - 3 (กลุ่มงานที่ 3)' },
  { id: '4-4',  title: 'ผู้ช่วยวิศวกรโครงการ - งานโครงสร้าง/โยธา กลุ่มงานที่ 1' },
  { id: '5',    title: 'วิศวกร/สถาปนิกโครงการ งานอาคาร' },
  { id: '6-1',  title: 'รองวิศวกร/สถาปนิกโครงการ งานอาคาร' },
  { id: '6-2',  title: 'ผู้ช่วยวิศวกร/สถาปนิกโครงการ งานอาคาร' },
  { id: '7-1',  title: 'ผู้เชี่ยวชาญงานปรับปรุงคุณภาพดิน - 1 (กลุ่มงานที่ 1 และ 2)' }, 
  { id: '7-2',  title: 'ผู้เชี่ยวชาญงานปรับปรุงคุณภาพดิน - 2 (กลุ่มงานที่ 3)' },
  { id: '8-1',  title: 'วิศวกรด้านงานปรับปรุงคุณภาพดิน - 1 (กลุ่มงานที่ 1 และ 2)' },
  { id: '8-2',  title: 'วิศวกรด้านงานปรับปรุงคุณภาพดิน - 2 (กลุ่มงานที่ 3)' },
  { id: '9',    title: 'วิศวกรงานระบบสาธารณูปโภค' },
  { id: '10',   title: 'ผู้ประสานงานโครงการ' },
  { id: '11',   title: 'ผู้เชี่ยวชาญด้านควบคุมระบบเอกสารและระบบสารสนเทศ' },
  { id: '12',   title: 'ผู้เชี่ยวชาญด้านผิวทางวิ่งทางขับ' },
  { id: '13-1', title: 'วิศวกรด้านผิวทางวิ่งทางขับ - 1 (กลุ่มงานที่ 1)' },
  { id: '13-2', title: 'วิศวกรด้านผิวทางวิ่งทางขับ - 2 (กลุ่มงานที่ 3)' },
  { id: '14',   title: 'ผู้เชี่ยวชาญด้านไฟฟ้าทางวิ่งทางขับ' },
  { id: '15-1', title: 'วิศวกรด้านไฟฟ้าสนามบิน - 1 (กลุ่มงานที่ 1)' },
  { id: '15-2', title: 'วิศวกรด้านไฟฟ้าสนามบิน - 2 (กลุ่มงานที่ 3)' },
  { id: '16',   title: 'ผู้เชี่ยวชาญด้านเอกสารและสัญญา' },
  { id: '17',   title: 'วิศวกรด้านแผนงานก่อสร้าง' },
  { id: '18',   title: 'ผู้เชี่ยวชาญด้านงบประมาณและควบคุมราคา' },
  { id: '19',   title: 'ผู้เชี่ยวชาญด้านระบบรับรองคุณภาพ' },
  { id: '20',   title: 'ผู้เชี่ยวชาญด้านความปลอดภัยในการก่อสร้าง' },
  { id: '21',   title: 'วิศวกรโครงสร้างอาวุโส' },
  { id: '22',   title: 'วิศวกรโครงสร้าง' },
  { id: '23',   title: 'วิศวกรโยธาอาวุโส' },
  { id: '24',   title: 'วิศวกรโยธา' },
  { id: '25',   title: 'สถาปนิกอาวุโส' },
  { id: '26',   title: 'สถาปนิก' },
  { id: '27-1', title: 'วิศวกรระบายน้ำอาวุโส' },
  { id: '27-2', title: 'วิศวกรระบายน้ำ' },
  { id: '28',   title: 'ผู้เชี่ยวชาญด้านสิ่งแวดล้อม' },
  { id: '29',   title: 'ผู้เชี่ยวชาญด้านวัสดุก่อสร้าง' },
  { id: '30',   title: 'วิศวกรปฐพีกลศาสตร์' },
  { id: '31-1', title: 'วิศวกรสำรวจอาวุโส' },
  { id: '31-2', title: 'วิศวกรสำรวจ' },
  { id: '32',   title: 'วิศวกรไฟฟ้ากำลังอาวุโส' },
  { id: '33',   title: 'วิศวกรไฟฟ้ากำลัง' },
  { id: '34',   title: 'วิศวกรไฟฟ้าสื่อสารอาวุโส' },
  { id: '35',   title: 'วิศวกรคอมพิวเตอร์หรือเทคโนโลยีสารสนเทศ' },
  { id: '36',   title: 'วิศวกรเครื่องกลอาวุโส' },
  { id: '37',   title: 'วิศวกรสุขาภิบาลอาวุโส' },
  { id: '38',   title: 'ผู้เชี่ยวชาญด้านประชาสัมพันธ์' },
  { id: '39',   title: 'วิศวกรประจำโครงการ' },
  { id: '0',    title: 'ผู้ช่วยผู้จัดการโครงการ' },
  null,
  { id: '1-1',  title: 'ผู้ช่วยวิศวกรสำนักงาน 1' },
  { id: '1-2',  title: 'ผู้ช่วยวิศวกรสำนักงาน 2' },
  { id: '2-1',  title: 'ผู้ช่วยวิศวกรด้านการจัดทำรายงานและงาน Presentation 1' },
  { id: '2-2',  title: 'ผู้ช่วยวิศวกรด้านการจัดทำรายงานและงาน Presentation 2' },
  { id: '3-1',  title: 'ผู้ช่วยวิศวกรด้านวางแผนงานก่อสร้าง 1' },
  { id: '3-2',  title: 'ผู้ช่วยวิศวกรด้านวางแผนงานก่อสร้าง 2' },
  { id: '4-1',  title: 'เจ้าหน้าที่ด้านควบคุมเอกสาร 1' },
  { id: '4-2',  title: 'เจ้าหน้าที่ด้านควบคุมเอกสาร 2' },
  { id: '5-1',  title: 'ผู้ช่วยวิศวกรด้านเอกสารสัญญา 1' },
  { id: '5-2',  title: 'ผู้ช่วยวิศวกรด้านเอกสารสัญญา 2' },
  { id: '6-1',  title: 'ผู้ช่วยวิศวกรด้านแผนงาน 1' },
  { id: '6-2',  title: 'ผู้ช่วยวิศวกรด้านแผนงาน 2' },
  { id: '7-1',  title: 'ผู้ช่วยวิศวกรด้านงบประมาณและควบคุมราคา 1' },
  { id: '7-2',  title: 'ผู้ช่วยวิศวกรด้านงบประมาณและควบคุมราคา 2' },
  { id: '7-3',  title: 'ผู้ช่วยวิศวกรด้านงบประมาณและควบคุมราคา 3' },
  { id: '8-1',  title: 'เจ้าหน้าที่ด้านความปลอดภัย 1' },
  { id: '8-2',  title: 'เจ้าหน้าที่ด้านความปลอดภัย 2' },
  { id: '8-3',  title: 'เจ้าหน้าที่ด้านความปลอดภัย 3' },
  { id: '8-4',  title: 'เจ้าหน้าที่ด้านความปลอดภัย 4' },
  { id: '9-1',  title: 'ผู้ช่วยผู้เชี่ยวชาญด้านสิ่งแวดล้อม 1' },
  { id: '9-2',  title: 'ผู้ช่วยผู้เชี่ยวชาญด้านสิ่งแวดล้อม 2' },
  { id: '10-1', title: 'ผู้ช่วยวิศวกรโยธา 1' },
  { id: '10-2', title: 'ผู้ช่วยวิศวกรโยธา 2' },
  { id: '11-1', title: 'ผู้ช่วยวิศวกรด้านปฐพีวิทยา 1' },
  { id: '11-2', title: 'ผู้ช่วยวิศวกรด้านปฐพีวิทยา 2' },
  { id: '12-1', title: 'ผู้ช่วยวิศวกรสำรวจ 1' },
  { id: '12-2', title: 'ผู้ช่วยวิศวกรสำรวจ 2' },
  { id: '12-3', title: 'ผู้ช่วยวิศวกรสำรวจ 3' },
  { id: '13-1', title: 'ผู้ช่วยวิศวกรไฟฟ้ากำลัง 1' },
  { id: '13-2', title: 'ผู้ช่วยวิศวกรไฟฟ้ากำลัง 2' },
  { id: '14',   title: 'ผู้ช่วยวิศวกรไฟฟ้าสื่อสาร ' },
  { id: '15',   title: 'ผู้ช่วยวิศวกรเครื่องกล' },
  { id: '16-1', title: 'สถาปนิกผู้ช่วย 1' },
  { id: '16-2', title: 'สถาปนิกผู้ช่วย 2' },
  { id: '17-1', title: 'เจ้าหน้าที่ด้านการประชาสัมพันธ์ 1' },
  { id: '17-2', title: 'เจ้าหน้าที่ด้านการประชาสัมพันธ์ 2' },
  { id: '18-1', title: 'วิศกรสนาม-งานโครงสร้าง/โยธา 1' },
  { id: '18-2', title: 'วิศกรสนาม-งานโครงสร้าง/โยธา 2' },
  { id: '18-3', title: 'วิศกรสนาม-งานโครงสร้าง/โยธา 3' },
  { id: '18-4', title: 'วิศกรสนาม-งานโครงสร้าง/โยธา 4' },
  { id: '18-5', title: 'วิศกรสนาม-งานโครงสร้าง/โยธา 5' },
  { id: '19-1', title: 'วิศกรสนาม-งานระบบไฟฟ้าเครื่องกล 1' },
  { id: '19-2', title: 'วิศกรสนาม-งานระบบไฟฟ้าเครื่องกล 2' },
  { id: '20-1', title: 'วิศวกรสนาม-วัสดุ/ปริมาณงาน/ราคา 1' },
  { id: '20-2', title: 'วิศวกรสนาม-วัสดุ/ปริมาณงาน/ราคา 2' },
  { id: '20-3', title: 'วิศวกรสนาม-วัสดุ/ปริมาณงาน/ราคา 3' },
  { id: '21-1', title: 'สถาปนิกสนาม 1' },
  { id: '21-2', title: 'สถาปนิกสนาม 2' },
  { id: '22',   title: 'เลขานุการโครงการ' },
  { id: '23',   title: 'เจ้าหน้าที่แปลเอกสาร' },
  { id: '24-1', title: 'ช่างเทคนิค-งานสำรวจ 1' },
  { id: '24-2', title: 'ช่างเทคนิค-งานสำรวจ 2' },
  { id: '24-3', title: 'ช่างเทคนิค-งานสำรวจ 3' },
  { id: '24-4', title: 'ช่างเทคนิค-งานสำรวจ 4' },
  { id: '25-1', title: 'ช่างเทคนิค-งานโครงสร้าง/โยธา 1' },
  { id: '25-2', title: 'ช่างเทคนิค-งานโครงสร้าง/โยธา 2' },
  { id: '25-3', title: 'ช่างเทคนิค-งานโครงสร้าง/โยธา 3' },
  { id: '25-4', title: 'ช่างเทคนิค-งานโครงสร้าง/โยธา 4' },
  { id: '25-5', title: 'ช่างเทคนิค-งานโครงสร้าง/โยธา 5' },
  { id: '25-6', title: 'ช่างเทคนิค-งานโครงสร้าง/โยธา 6' },
  { id: '26-1', title: 'ช่างเทคนิค-งานสถาปัตยกรรม 1' },
  { id: '26-2', title: 'ช่างเทคนิค-งานสถาปัตยกรรม 2' },
  { id: '26-3', title: 'ช่างเทคนิค-งานสถาปัตยกรรม 3' },
  { id: '26-4', title: 'ช่างเทคนิค-งานสถาปัตยกรรม 4' },
  { id: '27-1', title: 'ช่างเทคนิค-งานระบบ 1' },
  { id: '27-2', title: 'ช่างเทคนิค-งานระบบ 2' },
  { id: '27-3', title: 'ช่างเทคนิค-งานระบบ 3' },
  { id: '27-4', title: 'ช่างเทคนิค-งานระบบ 4' },
  { id: '28-1', title: 'ช่างเทคนิค-งานทดสอบวัสดุ 1' },
  { id: '28-2', title: 'ช่างเทคนิค-งานทดสอบวัสดุ 2' },
  { id: '28-3', title: 'ช่างเทคนิค-งานทดสอบวัสดุ 3' },
  { id: '28-4', title: 'ช่างเทคนิค-งานทดสอบวัสดุ 4' },
  { id: '29-1', title: 'เจ้าหน้าที่ด้าน IT 1' },
  { id: '29-2', title: 'เจ้าหน้าที่ด้าน IT 2' },
  { id: '30-1', title: 'เจ้าหน้าที่บัญชี 1' },
  { id: '30-2', title: 'เจ้าหน้าที่บัญชี 2' },
  { id: '31-1', title: 'เจ้าหน้าที่เขียนแบบ 1' },
  { id: '31-2', title: 'เจ้าหน้าที่เขียนแบบ 2' },
  { id: '32-1', title: 'เจ้าหน้าที่กราฟฟิค 1' },
  { id: '32-2', title: 'เจ้าหน้าที่กราฟฟิค 2' },
  { id: '33-1', title: 'ผู้ช่วยเลขานุการ 1' },
  { id: '33-2', title: 'ผู้ช่วยเลขานุการ 2' },
  { id: '34-1', title: 'เจ้าหน้าที่ธุรการ 1' },
  { id: '34-2', title: 'เจ้าหน้าที่ธุรการ 2' },
  { id: '34-3', title: 'เจ้าหน้าที่ธุรการ 3' },
  { id: '34-4', title: 'เจ้าหน้าที่ธุรการ 4' }
];