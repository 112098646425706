export enum Locale {
  Thai = 'th',
  English = 'en'
};
export enum YearFormat { BE, AD };

export const Salutations = [
  'นาย', 'นาง', 'นางสาว',
  'หม่อมหลวง', 'หม่อมราชวงศ์', 'หม่อมเจ้า',
  'ว่าที่ ร.ต.', 'ว่าที่ ร.ต.หญิง'
];
export const EnglishSalutations = [
  'Mr.', 'Mrs.', 'Ms.',
  'M.L.', 'M.R.', 'M.C.',
  'Acting Sub.Lt.', 'Acting Sub.Lt.'
];
export const ShortDayNames = [ 'อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส' ];
export const DayNames = [ 'อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์' ];
export const AbbrMonthNames = [ 
  'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
  'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
];
export const FullMonthNames = [
  'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
  'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
];


export const FullDayNameTh = [ 'อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์' ];
export const ShortDayNameTh = [ 'อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์' ];
export const AbbrDayNameTh = [ 'อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.' ];
export const FullMonthNameTh = [
  'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
  'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
];
export const AbbrMonthNameTh = [
  'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
  'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
];

export const FullDayNameEn = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
export const ShortDayNameEn = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
export const AbbrDayNameEn = [ 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa' ];
export const FullMonthNameEn = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];
export const AbbrMonthNameEn = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];
export const BkkTimezone = 'Asia/Bangkok';