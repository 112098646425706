export const Exception = {
  // Application Error
  ApplicationError: 'Error.ApplicationError',

  // API Error
  CannotEstablishAPICall: 'Error.CannotEstablishAPICall',
  
  // Authentication Error
  UserIsNotSignedIn: 'Error.UserIsNotSignedIn',
  UserDoesNotExist: 'Error.UserDoesNotExist',
  DisabledUser: 'Error.DisabledUser',
  InvalidPassword: 'Error.InvalidPassword',
  InvalidUserIdOrPassword: 'Error.InvalidUserIdOrPassword',
  InvalidUserIdOrPasswordFormat: 'Error.InvalidUserIdOrPasswordFormat',
  CannotAuthenticateUser: 'Error.CannotAuthenticateUser',
  CannotSignOut: 'Error.CannotSignOut',
  NumberOfPasswordChangedExceedLimit: 'Error.NumberOfPasswordChangedExceedLimit',
  CannotChangePassword: 'Error.CannotChangePassword',
  CannotInitiateForgotPassword: 'Error.CannotInitiateForgotPassword',
  InvalidVerificationCode: 'Error.InvalidVerificationCode',
  ExpiredVerificationCode: 'Error.ExpiredVerificationCode',
  CannotConfirmForgotPassword: 'Error.CannotConfirmForgotPassword'
};