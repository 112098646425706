import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { ForgotPasswordDialogComponent } from './forgot-password-dialog.component';


@Injectable()
export class ForgotPasswordDialog {


  // Constructor ___________________________________________________________________________________

  constructor(private dialog: MatDialog) { }


  // Public Methods ________________________________________________________________________________

  //................................................................................................
  public present() {
    this.dialog.open(ForgotPasswordDialogComponent, { width: '320px', panelClass: 'pz-dialog-container' });
  }
}