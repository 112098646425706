import * as moment from 'moment';

import { Injectable } from '@angular/core';

import { Workbook, Worksheet } from 'exceljs';

import { saveAs } from 'file-saver';

import { ApiService } from 'src/services/api';
import { Utility } from 'src/services/utility.service';


import {
  NormalStyle,
  Title1Style, Title2Style,
  TableHeaderStyle,
  TableTextStyle, TableTextCenterStyle,
  TableIntegerStyle, TableDateStyle, TableTimeStyle,
  Columns, ColumnLabels
} from './access-log.definition';


@Injectable()
export class AccessLog {


  // Constructor ___________________________________________________________________________________

  constructor(private api: ApiService) { }


  // Public Methods ________________________________________________________________________________

  //................................................................................................
  public async create(date: Date): Promise<string | null> {
    let accessLog = await this.api.logger.get(date);
    if (accessLog !== null) {
      let workbook = new Workbook();
      let sheet = workbook.addWorksheet('Access Log');
      this.setTitle(sheet, date);
      this.setHeader(sheet);
      this.setBody(sheet, accessLog);  
      await this.createFile(workbook, date);
      return 'Success';
    } else {
      return null;
    }
  }


  // Private Methods _______________________________________________________________________________

  //................................................................................................
  private setTitle(sheet: Worksheet, date: Date) {
    this.setCell(sheet, 'A', 1, 'บันทึกการดูรายงานเวลาเข้า-ออก', Title1Style);
    this.setCell(sheet, 'A', 2, `วันที่ ${Utility.dateFormat(date, 'FFF')}`, Title2Style);
  }

  //................................................................................................
  private setHeader(sheet: Worksheet) {
    Columns.forEach((column, idx) => sheet.getColumn(idx + 1).width = column.width);
    ColumnLabels.forEach(column =>  {
      this.setCell(sheet, column.column, column.row, column.label, TableHeaderStyle)
    });
  }

  //................................................................................................
  private setBody(sheet: Worksheet, accessLog: Array<any>) {
    let row = 5;
    for (let idx = 0; idx < accessLog.length; idx++) {
      let log = accessLog[idx];
      this.setCell(sheet, 'A', row, moment(log.accessDate).format('HH:mm'), TableTextCenterStyle);
      this.setCell(sheet, 'B', row, Utility.dateFormat(log.reportDate, 'SSF'), TableTextCenterStyle);
      this.setCell(sheet, 'C', row, log.name, TableTextStyle);
      this.setCell(sheet, 'D', row, log.surname, TableTextStyle);
      row += 1;
    }
  }

  //................................................................................................
  private setCell(sheet: Worksheet, column: string, row: number, value: any, style: any = NormalStyle) {
    let cell = sheet.getCell(column + row);
    cell.value = value;
    if (style.font) {
      cell.font = style.font;
    }
    if (style.alignment) {
      cell.alignment = style.alignment;
    }
    if (style.numFmt) {
      cell.numFmt = style.numFmt;
    }
    if (style.border) {
      cell.border = style.border;
    }
    if (style.fill) {
      cell.fill = style.fill;
    }
  }

  //................................................................................................
  private async createFile(workbook: Workbook, date: Date) {
    let filename = `Access Log - ${moment(date).format('DD-MM-YY')}.xlsx`;
    let data = await workbook.xlsx.writeBuffer();
    const blob = new Blob([ data ], { type: 'application/octet-stream' });
    saveAs(blob, filename);
  }
}