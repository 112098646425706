import { Component, OnInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';

import { AuthenticationStatus } from 'src/definitions/user';
import { CanDeactivateGuard  } from 'src/definitions/deactivate-guard';

import { UserState } from 'src/user-state';


@Component({
  selector: 'proZper-AOT',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  // Constructor ___________________________________________________________________________________

  constructor(private location: PlatformLocation,
    private router: Router,
    private user: UserState) {

    this.location.onPopState(() => {
      console.log('Back button clicked...');
      CanDeactivateGuard.backButtonClicked = true;
      return false;
    });  
  }


  // Lifecycle Hooks _______________________________________________________________________________

  //................................................................................................
  public async ngOnInit() {
    let authenticationStatus = await this.user.reinstate();
    if (authenticationStatus === AuthenticationStatus.SignedOut) {
      this.router.navigate([ '/signin' ]);
    }
  }


  // Accessors _____________________________________________________________________________________

  //................................................................................................
  public get signedIn(): boolean {
    return this.user.authenticationStatus === AuthenticationStatus.SignedIn;
  }


  // Public Methods ________________________________________________________________________________

  //................................................................................................
  public toggleUserMenu() {
    this.user.signOut();
    this.router.navigate([ '/signin' ]);
  }
}