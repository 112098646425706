import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SnackBar } from './snack-bar';
import { SnackBarComponent } from './snack-bar.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};


@NgModule({
  declarations: [ SnackBarComponent ],
  imports: [
    CommonModule, HttpClientModule,
    MatButtonModule, MatIconModule, MatSnackBarModule,
    
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: true,
      defaultLanguage: 'th'
    })
  ],
  providers: [ SnackBar ]
})
export class SnackBarModule {

  
  // Constructor ___________________________________________________________________________________

  constructor(private translate: TranslateService) {
    this.translate.use('th');
  }
}