import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Exception } from 'src/definitions/exception';

const aotUnauthenticatedAPI = 'https://848a7lea17.execute-api.ap-southeast-1.amazonaws.com/prod';
const UnauthenticatedAPIKey = 'gf1YSFsLp27VEQiEpgzfj4Wt5FqV4ecP2LceFrhD';
const InitiateForgotPasswordAPI = `${aotUnauthenticatedAPI}/user/forgot-password/initiate`;
const ConfirmForgotPasswordAPI = `${aotUnauthenticatedAPI}/user/forgot-password/confirm`;


@Injectable()
export class UnauthenticatedAPI {
  public static user = {
    forgotPassword: {
      initiate: UnauthenticatedAPI.initiateForgotPassword,
      confirm: UnauthenticatedAPI.confirmForgotPassword
    }
  };

  private static httpClient: HttpClient;


  // Constructor ___________________________________________________________________________________
  constructor(private httpClient: HttpClient) {
    UnauthenticatedAPI.httpClient = this.httpClient;
  }


  //................................................................................................
  private static initiateForgotPassword(username: string) {
    return new Promise((resolve, reject) => {
      let body = {
        username: username
      };
      let options = { headers: new HttpHeaders({ 'X-Api-Key': UnauthenticatedAPIKey }) };
      UnauthenticatedAPI.httpClient.post(InitiateForgotPasswordAPI, body, options).subscribe({
        next: (response: any) => {
          if (response.CodeDeliveryDetails) {
            resolve(response);
          } else {
            reject(new Error(response.errorMessage ? response.errorMessage : Exception.CannotInitiateForgotPassword));
          }
        },
        error: (error) => {
          reject(Exception.CannotInitiateForgotPassword);
        }
      });
    });
  }

  //................................................................................................
  private static confirmForgotPassword(verificationCode: string, username: string, password: string) {
    return new Promise((resolve, reject) => {
      let body = {
        verificationCode: verificationCode,
        username: username,
        password: password
      };
      let options = { headers: new HttpHeaders({ 'X-Api-Key': UnauthenticatedAPIKey }) };
      UnauthenticatedAPI.httpClient.post(ConfirmForgotPasswordAPI, body, options).subscribe({
        next: (response: any) => {
          if (response.errorMessage) {
            reject(new Error(response.errorMessage));
          } else {
            resolve('Success');
          }
        },
        error: (error) => {
          console.log(error);
          reject(new Error(Exception.CannotConfirmForgotPassword));
        }
      });
    });
  }
}